import { signIn, signOut } from "next-auth/react";
import { deleteDeviceToken } from "./queries";

export const updateUserSession = async (credentials) => {
  const result = await signIn("credentials", {
    ...credentials,
    type: "update_session",
    redirect: false,
  });
  return result;
};

export const signOutUser = ({ session = null, signOutParams = {}, afterSignOut = null } = {}) => {
  if (session?.user?.deviceToken) {
    deleteDeviceToken(session.user.deviceToken)
      .then((response) => handleSignOut({ signOutParams: signOutParams, afterSignOut: afterSignOut }))
      .catch((error) => handleSignOut({ signOutParams: signOutParams, afterSignOut: afterSignOut }));
  } else {
    handleSignOut({ signOutParams: signOutParams, afterSignOut: afterSignOut });
  }
};

const handleSignOut = ({ signOutParams = {}, afterSignOut = null } = {}) => {
  signOut(signOutParams).then(() => {
    if (afterSignOut) afterSignOut();
  });
};
