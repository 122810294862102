import axios from "axios";
import { getSession } from "next-auth/react";
import { captureException, setContext } from "@sentry/nextjs";
import { signOutUser } from "./session";

export const removeEmptyStrings = (obj) => {
  const newObj = {};

  for (let key in obj) {
    if (obj[key] === "") {
      newObj[key] = null;
    } else {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const bearerHeader = (session) => `Bearer ${session?.user?.accessToken}`;

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_LOCAL_URL || "http://localhost:3001",
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(async (request) => {
  const session = await getSession();

  if (session?.user) {
    request.headers.Authorization = bearerHeader(session);
  }

  request.headers["Accept-Language"] = session?.user?.locale || "en";

  return request;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    const not_signed_message = "Not signed in";

    if (err.response?.status === 401 && err.response?.data?.errors?.at(0) === not_signed_message) {
      if (err.response.config.url.includes("verify_token")) {
        signOutUser();
      } else {
        verifySessionToken().catch((error) => {
          if (err.response?.status === 401 && err.response?.data?.errors?.at(0) === not_signed_message) {
            signOutUser();
          }
        });
      }
    }

    setContext("error_data", {
      base_url: err.response?.config?.baseURL,
      pathname: err.response?.config?.url,
      params: JSON.stringify(err.response?.config?.params),
      incomming_errors: err.response?.data?.errors,
    });
    captureException(err);

    return Promise.reject(err);
  }
);

export const handleError = (error, router) => {
  const status = error.response?.status;
  if (!status) return;

  if (status == 403) router.replace("/403");
  if (status == 404) router.replace("/404");
  if (status == 500) router.replace("/500");
};

export const getGeneratePin = (params) => {
  return api.post("/api/v1/sign_in/generate_pin", params);
};

export const singInPost = async (phone, pin) => {
  const { data } = await api.get("/api/v1/sign_in", {
    params: {
      phone: phone,
      pin: pin,
    },
  });

  return data;
};

export const patchUser = (user) => {
  return api.patch("/api/v1/profile", { user: removeEmptyStrings(user) });
};

export const uploadAvatar = (file) => {
  const formData = new FormData();
  formData.append("avatar", file);

  return api.post("/api/v1/profile/upload_avatar", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getUser = async (id) => {
  const { data } = await api.get(`/api/v1/profile/${id}`);
  return data;
};

export const getActivityGroups = async ({ withActivities }) => {
  const { data } = await api.get("/api/v1/activity_groups", {
    params: { with_activities: withActivities },
  });

  return data;
};

export const getActivities = async (activityGroupId) => {
  if (!activityGroupId) return [];

  const { data } = await api.get(`/api/v1/activity_groups/${activityGroupId}/activities`);
  return data;
};

export const getCurrentUserActivities = async () => {
  const { data } = await api.get(`/api/v1/users_activities`);
  return data;
};

export const postUserActivities = async (users_activities) => {
  return api.post(`/api/v1/users_activities`, {
    users_activities: users_activities,
  });
};

export const getGroups = async (params) => {
  const { data } = await api.get("/api/v1/user/groups", {
    params: { page: params.pageParam, q: params.q, per_page: params.perPage },
  });
  return data;
};

export const getAdminGroups = async (params) => {
  const { data } = await api.get("/api/v1/user/admin_groups", {
    params: { page: params.pageParam, q: params.q, per_page: params.perPage },
  });
  return data;
};

export const postGroup = (formData) => {
  return api.post("/api/v1/groups", formData);
};

export const patchGroup = (formData) => {
  return api.patch(`/api/v1/groups/${formData.token}`, formData);
};

export const getGroup = async (token) => {
  const { data } = await api.get(`/api/v1/groups/${token}`);
  return data;
};

export const patchUsersGroup = async (id, params) => {
  const { data } = await api.patch(`/api/v1/users_groups/${id}`, params);
  return data;
};

export const getSubgroups = async (token, params = {}) => {
  const { data } = await api.get(`/api/v1/groups/${token}/subgroups`, {
    params: params,
  });

  return data;
};

export const getLanguages = async () => {
  const { data } = await api.get("/api/v1/languages");
  return data;
};

export const getNotifications = async (params) => {
  const { data } = await api.get("/api/v1/notifications", {
    params: { page: params.pageParam, per_page: params.per_page, q: params.q },
  });
  return data;
};

export const deleteNotification = async (id) => {
  const { data } = await api.delete(`/api/v1/notifications/${id}`);
  return data;
};

export const getGroupMembers = async (params) => {
  const { data } = await api.get(`/api/v1/groups/${params.token}/members`, {
    params: { page: params.pageParam, per_page: params.per_page, q: params.q },
  });

  return data;
};

export const getGroupPolls = async (params) => {
  const { data } = await api.get(`/api/v1/groups/${params.token}/polls`, {
    params: { page: params.pageParam, per_page: params.per_page },
  });
  return data;
};

export const postPoll = async (data) => {
  return api.post("/api/v1/polls", data);
};

export const postSuggestion = (formData) => {
  return api.post("/api/v1/suggestions", formData);
};

export const postEvent = (data) => {
  return api.post("/api/v1/events", data);
};

export const patchEvent = (slug, data) => {
  return api.patch(`/api/v1/events/${slug}`, data);
};

export const getEventDetail = async (slug, headers = {}) => {
  const { data } = await api.get(`/api/v1/events/${slug}`, { headers: headers });
  return data;
};

export const getEventUsersWithHeaders = async (slug, headers) => {
  const { data } = await api.get(`/api/v1/events/${slug}`, { headers: headers });
  return data;
};

export const getEvents = async (params) => {
  const { data } = await api.get("/api/v1/events", {
    params: { q: params.q, page: params.pageParam },
  });
  return data;
};

export const getUserEvents = async (params) => {
  const { data } = await api.get("/api/v1/user/events", {
    params: { q: params.q, page: params.pageParam, per_page: params.per_page },
  });
  return data;
};

export const getGroupUsersForInvite = async (params) => {
  const { data } = await api.get(`/api/v1/groups/${params.token}/users_for_invite`, {
    params: {
      page: params.pageParam,
      q: params.q,
      per_page: params.per_page,
    },
  });
  return data;
};

export const postGroupInviteUser = (token, user_id) => {
  return api.post(`/api/v1/groups/${token}/invite_user`, {
    user_id: user_id,
  });
};

export const joinUserToEvent = (slug, user_id) => {
  return api.post(`/api/v1/events/${slug}/join`, {
    user_id: user_id,
  });
};

export const deleteUserFromEvent = (users_event_id) => {
  return api.delete(`/api/v1/users_events/${users_event_id}`);
};

export const deleteGroup = (token) => {
  return api.delete(`/api/v1/groups/${token}`);
};

export const searchEvents = async (params) => {
  const { data } = await api.get(`/api/v1/search`, {
    params: { page: params.pageParam, q: params.q, per_page: 10 },
  });

  return data;
};

export const approveUsersGroup = async (id) => {
  const { data } = await api.get(`/api/v1/users_groups/${id}/approve`);
  return data;
};

export const deleteUsersGroup = async (id) => {
  return api.delete(`/api/v1/users_groups/${id}`);
};

export const approveUsersEvent = async (id) => {
  const { data } = await api.get(`/api/v1/users_events/${id}/approve`);
  return data;
};

export const declineUsersEvent = async (id) => {
  const { data } = await api.get(`/api/v1/users_events/${id}/decline`);
  return data;
};

export const getPoll = async (id) => {
  const { data } = await api.get(`/api/v1/polls/${id}`);
  return data;
};

export const sendPollAnswers = (id, option_ids) => {
  return api.post(`/api/v1/polls/${id}/polls_answers`, {
    vote_poll_options_ids: option_ids,
  });
};

export const getChatRooms = async (params) => {
  const { data } = await api.get(`/api/v1/chat_rooms`, {
    params: { page: params.pageParam, per_page: params.per_page, q: params.q },
  });

  return data;
};

export const getChatRoom = async (id) => {
  const { data } = await api.get(`/api/v1/chat_rooms/${id}`);
  return data;
};

export const getGroupChatRoom = async (token) => {
  const { data } = await api.get(`/api/v1/groups/${token}/chat_room`);
  return data;
};

export const getEventChatRoom = async (slug) => {
  const { data } = await api.get(`/api/v1/events/${slug}/chat_room`);
  return data;
};

export const getChatRoomMessages = async (params) => {
  const { data } = await api.get(`/api/v1/chat_rooms/${params.id}/messages`, {
    params: {
      q: params.q,
      page: params.pageParam,
      per_page: params.per_page,
    },
  });
  return data;
};

export const postMessage = async (chat_room_id, body) => {
  const { data } = await api.post(`/api/v1/chat_rooms/${chat_room_id}/message`, {
    message: { body: body },
  });
  return data;
};

export const postRatings = async (slug, ratings) => {
  const { data } = await api.post(`/api/v1/events/${slug}/ratings`, { ratings: ratings });
  return data;
};

export const getUsersRatings = async (slug) => {
  const { data } = await api.get(`/api/v1/events/${slug}/ratings`);
  return data;
};

export const getEventUsersForInvite = async (params) => {
  const { data } = await api.get(`/api/v1/events/${params.slug}/users_for_invite`, {
    params: {
      page: params.pageParam,
      q: params.q,
      per_page: params.per_page,
    },
  });
  return data;
};

export const postEventInviteUser = (slug, user_id) => {
  return api.post(`/api/v1/events/${slug}/invite_user`, {
    user_id: user_id,
  });
};

export const getUsersForInvite = async (params) => {
  const { data } = await api.get(`/api/v1/users_for_invite`, {
    params: {
      page: params.pageParam,
      q: params.q,
      per_page: params.per_page,
    },
  });

  return data;
};

export const deleteEvent = async (slug) => {
  const { data } = await api.delete(`/api/v1/events/${slug}`);
  return data;
};

export const getUnreadNotificationsCount = async () => {
  const { data } = await api.get(`/api/v1/notifications/unread`);
  return data;
};

export const markAllNotificationsAsRead = async () => {
  const { data } = await api.get(`/api/v1/notifications/mark_all_as_read`);
  return data;
};

export const getHandleNotification = async (id, handler) => {
  const { data } = await api.get(`/api/v1/notifications/${id}/handle`, { params: { handler: handler } });
  return data;
};

export const getAddress = async (lat, lng) => {
  const { data } = await api.get(`/api/v1/geo_locations/address`, { params: { lat: lat, lng: lng } });
  return data;
};

export const deleteProfile = async () => {
  const { data } = await api.delete(`/api/v1/profile`);
  return data;
};

export const hasUnreadMessages = async () => {
  const { data } = await api.get(`/api/v1/profile/has_unread_messages`);
  return data;
};

export const hasUnreadGroupsEvents = async () => {
  const { data } = await api.get(`/api/v1/profile/has_unread_groups_events`);
  return data;
};

export const usersGroupDisplayedNow = async (id) => {
  const { data } = await api.get(`/api/v1/users_groups/${id}/displayed_now`);
  return data;
};

export const hideChatRoom = async (id) => {
  const { data } = await api.delete(`/api/v1/chat_rooms/${id}/hide`);
  return data;
};

export const verifySessionToken = () => api.get("/api/v1/sessions/verify_token");

export const postDeviceToken = async (device) => {
  const { data } = await api.post(`/api/v1/devices`, { device: device });
  return data;
};

export const deleteDeviceToken = async (token) => {
  const { data } = await api.delete(`/api/v1/devices/${token}`);
  return data;
};

export const getPrivacyPolicy = async () => {
  const { data } = await api.get("/api/v1/privacy_policy");
  return data;
};

export const getTermsOfService = async () => {
  const { data } = await api.get("/api/v1/terms_of_service");
  return data;
};

export const confirmEmail = async (token) => {
  const { data } = await api.get(`/api/v1/user/confirm_email/${token}`);
  return data;
};

export const resendVerificationEmail = async () => {
  const { data } = await api.get(`/api/v1/profile/resend_verification_email`);
  return data;
};

export const getEmailPreferences = async () => {
  const { data } = await api.get("/api/v1/emails/preferences");
  return data;
};

export const getNotificationsPreferences = async () => {
  const { data } = await api.get("/api/v1/notifications/preferences");
  return data;
};

export const getShareableUsersInGroups = async (params) => {
  const { data } = await api.get("/api/v1/groups/shareable_users", { params: params });
  return data;
};

export const postChallenge = (data) => {
  return api.post("/api/v1/challenges", { challenge: data });
};

export const patchChallenge = (slug, data) => {
  return api.patch(`/api/v1/challenges/${slug}`, { challenge: data });
};

export const getChallengeDetail = async (slug, headers = {}) => {
  const { data } = await api.get(`/api/v1/challenges/${slug}`, { headers: headers });
  return data;
};

export const deleteChallenge = async (slug) => {
  const { data } = await api.delete(`/api/v1/challenges/${slug}`);
  return data;
};

export const joinUserToChallenge = (slug, user_id) => {
  return api.post(`/api/v1/challenges/${slug}/join`, {
    user_id: user_id,
  });
};

export const deleteUserFromChallenge = (users_challenge_id) => {
  return api.delete(`/api/v1/users_challenges/${users_challenge_id}`);
};

export const getChallengeUsersForInvite = async (params) => {
  const { data } = await api.get(`/api/v1/challenges/${params.slug}/users_for_invite`, {
    params: {
      page: params.pageParam,
      q: params.q,
      per_page: params.per_page,
    },
  });
  return data;
};

export const postChallengeInviteUser = (slug, user_id) => {
  return api.post(`/api/v1/challenges/${slug}/invite_user`, {
    user_id: user_id,
  });
};

export const getChallengeItem = async (id) => {
  const { data } = await api.get(`/api/v1/challenge_items/${id}`);
  return data;
};

export const postChallengeItemUser = (challenge_item_id) => {
  return api.post(`/api/v1/challenge_item_users/${challenge_item_id}`);
};

export const deleteChallengeItemUser = (challenge_item_id) => {
  return api.delete(`/api/v1/challenge_item_users/${challenge_item_id}`);
};

export const patchChallengeItemUser = (challenge_item_id, params) => {
  return api.patch(`/api/v1/challenge_item_users/${challenge_item_id}`, params);
};

export const getChallenges = async (params) => {
  const { data } = await api.get("/api/v1/challenges", {
    params: { q: params.q, page: params.pageParam },
  });
  return data;
};

export const getUserChallenges = async (params) => {
  const { data } = await api.get("/api/v1/user/challenges", {
    params: { q: params.q, page: params.pageParam, per_page: params.per_page },
  });
  return data;
};

export const searchChallenges = async (params) => {
  const { data } = await api.get(`/api/v1/user/challenges/search`, {
    params: { page: params.pageParam, q: params.q, per_page: 25 },
  });

  return data;
};

export const approveUsersChallenge = async (id) => {
  const { data } = await api.get(`/api/v1/users_challenges/${id}/approve`);
  return data;
};

export const deleteUsersChallenge = async (id) => {
  const { data } = await api.delete(`/api/v1/users_challenges/${id}`);
  return data;
};

export const getAvailableLocales = async () => {
  const { data } = await api.get(`/api/available_locales`);
  return data;
}

export const toggleChatRoomMute = async (id) => {
  const { data } = api.get(`/api/v1/chat_rooms/${id}/toggle_mute`);
  return data;
};
